import * as React from "react";

import Layout from "../components/layout/Layout";
import AmazonBanner from "../components/ui/banners/AmazonBanner";
import ContactsBanner from "../components/ui/banners/ContactsBanner";
import RoundedCornerBanner from "../components/ui/banners/RoundedCornerBanner";
import Seo from "../components/seo";

const IlNostroImpegno = () => {
  return (
    <>
      <Seo title="Il nostro impegno" description="Lorem ipsum dolor sit amet" />
      <Layout>
        <RoundedCornerBanner />
        <AmazonBanner />
        <ContactsBanner />
      </Layout>
    </>
  );
};

export default IlNostroImpegno;
