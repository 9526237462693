import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Banner from "./Banner";
import * as styles from "./RoundedCornerBanner.module.css";
import Card from "../Card";

const RoundedCornerBanner = () => {
  const bgStyle: React.CSSProperties = {
    borderRadius: "0 0 0 50px",
    height: "500px",
    opacity: "50%",
  };

  const bg = <StaticImage src="../../../assets/bokeh.jpg" alt="" />;
  const leftCardBg = <StaticImage src="../../../assets/scuola.jpg" alt="" />;

  return (
    <Banner bg={bg} bgStyle={bgStyle}>
      <div className={styles.bannerContentContainer}>
        <div className={styles.grid}>
          <div className={styles.leftTitle}>Progetto scuola</div>

          <div className={styles.leftContent}>
            <p>Lorem ipsum dolor sit amet</p>
            <p>consectetur adipiscing elit</p>
            <p>sed do eiusmod tempor incididunt ut labore</p>
          </div>

          <div className={styles.rightTitle}>In evidenza</div>

          <div className={styles.rightContent}>
            <div className={styles.cardContainer}>
              {/* TODO: add real content to cards */}
              <Card
                bg={leftCardBg}
                style={{ width: "300px", height: "320px", margin: "20px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <h4
                    style={{
                      marginBottom: "40px",
                    }}
                  >
                    Progetto scuola
                  </h4>
                </div>
              </Card>

              <Card
                style={{
                  width: "300px",
                  height: "320px",
                  margin: "20px",
                  backgroundColor: "#009EDF",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <StaticImage
                    src="../../../assets/lysoform_bagno.png"
                    alt=""
                    height={220}
                    placeholder="blurred"
                  />

                  <h4
                    style={{
                      marginBottom: "40px",
                      color: "white",
                    }}
                  >
                    Progetto scuola
                  </h4>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default RoundedCornerBanner;
